import axios, { AxiosError, AxiosInstance } from "axios";
import { EnrichmentData } from "./EnrichmentData";
import { EnrichmentResponse } from "./EnrichmentResponse";
import { RatingPartialResponse } from "./RatingPartialResponse";
import { RatingSubmitResponse } from "./RatingSubmitResponse";
import { SurveyRequest } from "./SurveyRequest";
import { Instance } from "./Instance";
import { RatingSubmission } from "./RatingSubmission";
import { SurveyRequestResponse } from "./SurveyRequestResponse";

/**
 * Classe de serviços para interagir com a API SoluCX
 */
export class SoluCXService {
  /**
   * Instância do objeto axios para requisições http
   */
  protected axios: AxiosInstance;

  /**
   * Construtor
   * @param apiKey chave de token da api para realizar as requisições http
   * @param baseURL URL da API SoluCX
   */
  public constructor(apiKey: string, baseURL: string = "https://app-api.solucx.com.br") {
    this.axios = axios.create({ baseURL, headers: { common: { "x-solucx-api-key": apiKey } } });
  }

  // Get ------------------------------------------------------------------------------------

  /**
   * Creates a new survey from SoluCX and create Transaction and related data;
   */
  public async CreateSurvey(surveyRequest: SurveyRequest): Promise<SurveyRequestResponse> {
    try {
      const { data: survey } = await this.axios.get<SurveyRequestResponse>("rating/form/", { params: surveyRequest });
      return survey;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  /**
   * Submete uma avaliação parcial
   * @param rating Avaliação Parcial
   */
  public async submitPartialRating(rating: RatingSubmission): Promise<RatingPartialResponse> {
    try {
      const { data: response } = await this.axios.put<RatingPartialResponse>("rating/form/", rating);
      return response;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  /**
   * Posta uma avaliação
   * @param rating Avaliação
   */
  public async submitRating(rating: RatingSubmission): Promise<RatingSubmitResponse> {
    try {
      const { data: response } = await this.axios.post<RatingSubmitResponse>("rating/form", rating);
      return response;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  /**
   * Submete dados de enriquecimento de base
   * @param enrichmentData Dados de enriquecimento
   */
  public async submitEnrichment(enrichmentData: EnrichmentData): Promise<EnrichmentResponse> {
    try {
      const { data: response } = await this.axios.put<EnrichmentResponse>("rating/form", enrichmentData);
      return response;
    } catch (error: any) {
      throw error.response.data;
    }
  }

  /**
   * Adquire dados de exibição da jornada
   */
  public async getJourneyParameters(journey: string = null): Promise<Instance> {
    try {
      const { data: response } = await this.axios.get<{ configApp: Instance }>("rating/config/");
      return response.configApp;
    } catch (error: any) {
      if (error.response.data && error.response.data.errors) throw error.response.data;
      else throw error;
    }
  }
}
