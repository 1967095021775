
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Survey } from '../service/Survey';
import { SurveyOptions } from '../service/SurveyOptions';
import { ClassItem } from '../service/ClassItem';
import { Source } from '../service/Source';
import { Dictionary } from 'vue-router/types/router';

enum WidgetState {
  loading = 'loading',
  score = 'score',
  likedislike = 'likedislike',
  comment = 'comment',
  erichment = 'erichment',
  sending = 'sending',
  done = 'done',
  error = 'error',
}

@Component({})
export default class DrogazilWidget extends Vue {
  private survey: Survey = null;
  public state: WidgetState = WidgetState.score;

  public rating: number = null;
  public comment: string = null;
  public items?: ClassItem[] = [];

  public surveySubmitted: boolean = false;
  public surveySubmitError: boolean = false;
  public sendRatingLoading: boolean = false;

  public parameters: any = null;
  public mainColor: string = '#249ab2';

  public async mounted() {
    window.addEventListener('keydown', this.closeWidgetByClickingOnTheEsc);
    const apiKey: string = this.$route.params['apiKey'];
    const options: SurveyOptions = this.translateRouteQueryToSurveyOptions();
    try {
      this.survey = await Survey.createSurvey(apiKey, options);
      parent.postMessage('openSoluCXWidget', '*');
      this.getNextState();

      this.mainColor = this.survey.parameters.color;
    } catch {
      parent.postMessage('dismissSoluCXWidget', '*');
    }
  }

  public destroyed() {
    window.removeEventListener('keydown', this.closeWidgetByClickingOnTheEsc);
  }

  @Watch('state')
  public onStatechanged(val: WidgetState, old: WidgetState) {
    if (val === WidgetState.likedislike) {
      setTimeout(() => {
        const w = document.body.scrollHeight;
        parent.postMessage('resizeSoluCXWidget-' + w, '*');
      }, 10);
    }
    if (val === WidgetState.comment) {
      setTimeout(() => {
        document.body.style.height = '280px';
        parent.postMessage('resizeSoluCXWidget-' + 280, '*');
      }, 10);
    }
  }

  public closeWidgetByClickingOnTheEsc(evt: { key: string }) {
    const key = evt.key;
    if (key === 'Escape') this.close();
  }

  public close(): void {
    parent.postMessage('closeSoluCXWidget', '*');
  }

  private translateRouteQueryToSurveyOptions(): SurveyOptions {
    return {
      ...this.addOption('transactionId', this.checkLength(this.$route.query['transaction_id'] as string) as string),
      ...this.addOption('clientId', this.checkLength(this.$route.query['client_id'] as string) as string),
      ...this.addOption('name', this.$route.query['name'] as string),
      ...this.addOption('phone', this.$route.query['phone'] as string),
      ...this.addOption('phone2', this.$route.query['phone2'] as string),
      ...this.addOption('cpf', this.$route.query['cpf'] as string),
      ...this.addOption('gender', this.$route.query['gender'] as string),
      ...this.addOption('birthDate', this.$route.query['birth_date'] as string),
      ...this.addOption('email', this.$route.query['email'] as string),
      ...this.addOption('storeId', this.checkLength(this.$route.query['store_id'] as string) as string),
      ...this.addOption('employeeId', this.checkLength(this.$route.query['employee_id'] as string) as string),
      ...this.addOption('amount', this.$route.query['amount'] as string),
      ...this.addOption('attemptId', this.$route.query['attempt_id'] as string),
      ...this.addOption('journey', this.$route.query['journey'] as string),
      ...this.addOption('extra', this.$route.query['extra'] as string),
      ...this.addOption('source', Source.widget as string),
      ...this.addParams(this.$route.query),
    };
  }

  private addParams(query: Dictionary<string | string[]>): Dictionary<string | string[]> {
    const params: Dictionary<string | string[]> = {};
    for (const p in query) {
      if (p.startsWith('param_')) {
        params[p] = query[p];
      }
    }
    return params;
  }

  private addOption(key: string, value: string | number) {
    if (value) {
      return { [key]: value };
    }
    return;
  }

  private checkLength(value: string) {
    if (!value) {
      return;
    }
    return value.length <= 36 ? value : value.substring(0, 36);
  }

  public async select(id: number) {
    if (this.survey.parameters.showClasses) this.state = WidgetState.likedislike;
    else this.state = WidgetState.comment;
    this.survey.rating = id;
    await this.survey.submitPartial();
    this.getNextState();
  }

  @Watch('items', { deep: true })
  public async onItemsChanged(val: ClassItem[], oldVal: ClassItem[]) {
    if (val.every(i => i.rating !== 'neutral')) {
      this.survey.items = this.items;
      await this.survey.submitPartial();
      this.state = WidgetState.comment;
      this.getNextState();
    }
  }

  private getNextState(): WidgetState {
    if (this.state === WidgetState.score) {
      return WidgetState.score;
    }

    if (this.state === WidgetState.likedislike) {
      this.items = this.survey.items.map(s => ({
        classId: s.classId,
        name: s.name,
        order: s.order,
        type: s.type,
        rating: 'neutral',
        score: 0,
      }));
      return WidgetState.likedislike;
    }

    if (this.state === WidgetState.comment) {
      return WidgetState.comment;
    }

    if (this.state === WidgetState.done) {
      return WidgetState.done;
    }
  }

  public async sendRating() {
    this.sendRatingLoading = true;
    this.survey.comment = this.comment;
    this.state = WidgetState.done;
    this.getNextState();

    try {
      const result = await this.survey.submitRating();
      this.surveySubmitted = true;
      parent.postMessage('completeSoluCXWidget', '*');
      setTimeout(() => {
        parent.postMessage('closeSoluCXWidget', '*');
      }, 3000);
    } catch {
      setTimeout(() => {
        parent.postMessage('dismissSoluCXWidget', '*');
      }, 3000);
      this.surveySubmitError = true;
    }

    this.sendRatingLoading = false;
  }
}
