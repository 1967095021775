import { ClassItem } from "./ClassItem";
import { JourneyParameters as Parameters } from "./JourneyParameters";
import { SurveyStatus } from "./SurveyStatus";
import { SoluCXService } from "./SoluCXService";
import { ClassItemResponse } from "./ClassItemResponse";
import { ClassItemRateSubmission } from "./ClassItemRateSubmission";
import { SurveyRequestResponse } from "./SurveyRequestResponse";
import { RatingSubmitResponse } from "./RatingSubmitResponse";
import { RatingPartialResponse } from "./RatingPartialResponse";
import { EnrichmentData } from "./EnrichmentData";
import { EnrichmentResponse } from "./EnrichmentResponse";
import { SurveyRequest } from "./SurveyRequest";
import { ClassRating } from "./ClassRating";
import { SurveyOptions } from "./SurveyOptions";

/**
 Objeto que representa a avaliação. Este é enviado para o backend durante a submissão de avaliação completa ou parcial
 */
export class Survey {
  private readonly solucxService: SoluCXService;

  public transactionId: string;
  public attemptId: string;
  public storeId?: string;
  public employeeId?: string;

  public parameters?: Parameters;

  public rating: number;
  public comment?: string;
  public items?: ClassItem[];
  public anonymous?: boolean;

  public params: { [param: string]: string };

  public constructor(apiKey: string) {
    this.solucxService = new SoluCXService(apiKey);
  }

  public static async createSurvey(apiKey: string, options: SurveyOptions): Promise<Survey> {
    const survey = new Survey(apiKey);
    await survey.initializeSurvey(options);
    return survey;
  }

  public getSurveyStatus(): SurveyStatus {
    if (this.transactionId == null) return SurveyStatus.surveyInvalid;
    if (this.attemptId == null) return SurveyStatus.surveyInvalid;
    if (this.rating == null) return SurveyStatus.scoreMissing;
    if (!this.parameters.classesNotRequired && this.items.some((i) => i.rating === null && i.score === null)) return SurveyStatus.itemsMissing;
    return SurveyStatus.valid;
  }

  public async initializeSurvey(options: SurveyOptions): Promise<SurveyRequestResponse> {
    const extraParams: object = {};

    for (const p in options) {
      if (p.startsWith("param_")){
        // @ts-ignore
        extraParams[p] = options[p];
      }
    }
    // normalize input
    const request: SurveyRequest = {
      transaction_id: options.transactionId,
      client_id: options.clientId,
      store_id: options.storeId,
      employee_id: options.employeeId,
      name: options.name,
      email: options.email,
      phone: options.phone,
      phone2: options.phone2,
      cpf: options.cpf,
      gender: options.gender,
      birth_date: options.birthDate,
      amount: options.amount == null ? 0 : options.amount,
      rating: options.rating == null ? -1 : options.rating,
      journey: options.journey,
      source: options.source,
      ...extraParams,
    };

    const survey: SurveyRequestResponse = await this.solucxService.CreateSurvey(request);

    this.parameters = survey.parameters;
    this.transactionId = survey.transactionId;
    this.attemptId = survey.attemptId;
    this.storeId = survey.storeId;
    this.employeeId = survey.employeeId;
    this.rating = options.rating;
    this.comment = null;
    this.anonymous = null;
    this.items = survey.classes?.map((i) => this.toClass(i)) ?? [];
    return survey;
  }

  public async submitRating(): Promise<RatingSubmitResponse> {
    const status = this.getSurveyStatus();
    if (status === SurveyStatus.valid) {
      return await this.solucxService.submitRating({
        transactionId: this.transactionId,
        storeId: this.storeId,
        attemptId: this.attemptId,
        employeeId: this.employeeId,
        anonymous: this.anonymous,
        comment: this.comment,
        items: this.items?.map((i) => this.fromClass(i)) ?? [],
        score: this.rating,
      });
    } else {
      throw status;
    }
  }

  public async submitPartial(): Promise<RatingPartialResponse> {
    return await this.solucxService.submitPartialRating({
      transactionId: this.transactionId,
      storeId: this.storeId,
      attemptId: this.attemptId,
      employeeId: this.employeeId,
      anonymous: this.anonymous,
      comment: this.comment,
      items: this.items?.map((i) => this.fromClass(i)) ?? [],
      score: this.rating,
    });
  }

  public async saveEnrichment(enrichmentData: EnrichmentData): Promise<EnrichmentResponse> {
    return await this.solucxService.submitEnrichment(enrichmentData);
  }

  private toClass(v: ClassItemResponse): ClassItem {
    return {
      classId: v.idClass,
      name: v.classe,
      order: v.order,
      type: v.type,
    };
  }

  private fromClass(v: ClassItem): ClassItemRateSubmission {
    return {
      classId: v.classId,
      like: this.likeFromRating(v.rating),
      score: v.score,
    };
  }

  private likeFromRating(rating: ClassRating): boolean {
    switch (rating) {
      case "positive":
        return true;
      case "negative":
        return false;
      case "neutral":
        return null;
    }
  }
}
