import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import DrogasilWidget from '@/views/DrogasilWidget.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/widget/:apiKey/',
    name: 'DrogasilWidget',
    component: DrogasilWidget,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
