export enum Source {
  email = "email",
  sms = "sms",
  push = "push",
  ura = "ura",
  facebook = "facebook",
  tablet = "tablet",
  whatsapp = "whatsapp",
  widget = "widget",
  qrcode = "qrcode",
}
